import { Brand } from './typings'

const infiniti: Brand = {
  clientInfo: {
    displayName: 'INFINITI',
    accountNumberLengths: [16],
    footerDisplay: 'INFINITI Finance Portal',
    footerLink: 'https://www.infinitifinance.com/',
    applicationUrl:
      'https://apply.syf.com/eapply/eapply.action?clientCode=INFINITIA',
    dsecApigeeClientId: {
      development: 'oSDQJLoUbCCcRBxeMYtRrgWpDv9Nb5nz',
      production: 'UcxLo0dm4URWnF36Nhk6Nr71AzGaXFVZ'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your INFINITI credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito INFINITI en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'INFINITI.com',
    hostname: 'infiniti.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your INFINITI® Visa Signature Credit Card and INFINITI® Visa Credit Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the INFINITI® Visa Signature Credit Card and INFINITI® Visa Credit Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of INFINITI.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-866-570-1420',
      fraud: '1-866-570-1420',
      customerService: '1-866-570-1420',
      plcc: ''
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@infiniti/en/html/ess003483.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@infiniti/en/html/ess003473.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@infiniti/en/html/ess003475.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@infiniti/en/html/ess003476.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#4d4d4d'
      },
      secondary: {
        hover: '#d9d9d9'
      },
      background: '#666666'
    },
    field: {
      icon: '#333333',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#000000',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#f2f2f2',
    bodyBackground: '#f2f2f2',
    iconBackground: '#cacaca',
    headerBackground: '#fff',
    modalBackground: '#FFF',
    headerDivider: '#000000',
    footerDivider: '#333',
    contrastingText: '#FFF',
    bodyText: '#333',
    languageSelector: '#333',
    focus: '',
    link: '#000000',
    footer: {
      background: '#333',
      text: '#fff',
      link: '#fff'
    },
    formBackground: ''
  },
  images: {
    logo: {
      en: '/assets/infiniti/logo.png',
      es: '/assets/infiniti/logo.png'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/infiniti/promo.png',
      es: '/assets/infiniti/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default infiniti
